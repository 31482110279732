<template>
    <div class="signUP-admin">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-5 col-md-5 p-0">
                    <div class="signUP-admin-left signIn-admin-left position-relative">
                        <div class="signUP-overlay">
                            <img class="svg signupTop" src="@/assets/img/svg/signupTop.svg" alt="img" />
                            <img class="svg signupBottom" src="@/assets/img/svg/signupBottom.svg" alt="img" />
                        </div>
                        <div class="signUP-admin-left__content">
                            <h1>{{ $t("apps.app_name") }}</h1>
                            <h3>{{ $t("apps.app_description") }}</h3>
                        </div>
                        <div class="signUP-admin-left__img">
                            <img class="img-fluid svg" src="@/assets/img/svg/signupIllustration.svg" alt="img" />
                        </div>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-7 col-md-7 col-sm-8 p-5">

<h1 class="text-center mb-3">Syarat dan Ketentuan</h1>

<p>Selamat datang di aplikasi Starsender. Dengan menggunakan layanan kami, Anda dianggap telah memahami dan menyetujui Syarat dan Ketentuan berikut:</p>
<ol>
<li><h5 class="mb-1">Penggunaan Layanan</h5>
<ul>
   <li>- Starsender menyediakan layanan pengelolaan pesan otomatis berbasis WhatsApp.</li>
  <li> - Anda wajib menggunakan layanan Starsender hanya untuk tujuan yang sah dan tidak melanggar hukum.</li>
   <li>- Penggunaan layanan yang melanggar peraturan hukum akan berakibat penghentian akses ke akun Anda tanpa pemberitahuan sebelumnya.</li>
   </ul>
   </li>

<li><h5 class="my-1">Pendaftaran Akun</h5>
<ul>
   <li>- Anda wajib memberikan informasi yang benar, akurat, dan lengkap saat mendaftar.</li>
   <li>- Anda bertanggung jawab atas keamanan akun Anda, termasuk menjaga kerahasiaan kata sandi.</li>
   <li>- Segala aktivitas yang dilakukan melalui akun Anda menjadi tanggung jawab penuh Anda.</li>
</ul>
</li>

<li><h5 class="my-1"> Kebijakan Biaya dan Pembayaran</h5>
<ul>
  <li> - Biaya layanan akan dikenakan sesuai dengan paket yang dipilih.</li>
   <li>- Semua pembayaran bersifat final dan tidak dapat dikembalikan kecuali dinyatakan sebaliknya dalam kebijakan pengembalian dana kami.</li>
</ul>
</li>

<li><h5 class="my-1"> Batasan Tanggung Jawab</h5>
<ul>
   <li>- Starsender tidak bertanggung jawab atas kerugian yang timbul akibat penyalahgunaan layanan oleh pengguna.</li>
   <li>- Starsender berhak untuk mengubah atau menghentikan layanan kapan saja dengan pemberitahuan sebelumnya.<li>
   <li>- Jika akun WhatsApp Anda terkena banned, hal tersebut tidak termasuk dalam garansi kami, karena layanan ini bersifat unofficial dan dapat berisiko terkena banned.</li>
</ul>
</li>
<li><h5 class="my-1"> Perubahan Syarat dan Ketentuan</h5>
<ul>
   <li>- Starsender dapat mengubah Syarat dan Ketentuan ini sewaktu-waktu.</li>
   <li>- Perubahan akan diinformasikan melalui email atau notifikasi aplikasi. Lanjutkan penggunaan layanan setelah pemberitahuan dianggap sebagai persetujuan atas perubahan.</li>
</ul>
</li>
</ol>

<h1 class="text-center mb-3">Kebijakan Privasi</h1>

<p>Kami di Starsender berkomitmen untuk melindungi privasi Anda. Kebijakan Privasi ini menjelaskan bagaimana kami mengumpulkan, menggunakan, dan melindungi informasi pribadi Anda.</p>
<ol>
<li><h5 class="mb-1">Informasi yang Dikumpulkan</h5>
<ul>
   <li>- Informasi yang Anda berikan: seperti nama, email, nomor telepon, dan informasi lainnya yang diperlukan untuk menggunakan layanan.</li>
   <li>- Informasi teknis: termasuk data penggunaan aplikasi, alamat IP, jenis perangkat, dan log aktivitas.</li>
 </ul>
</li>  

<li><h5 class="my-1"> Penggunaan Informasi</h5>
<ul>
   <li>- Informasi Anda digunakan untuk menyediakan, mengelola, dan meningkatkan layanan.</li>
   <li>- Kami dapat menggunakan informasi Anda untuk mengirimkan pembaruan, penawaran, atau informasi terkait layanan kami.</li>
  </ul>
</li>  

<li><h5 class="my-1"> Keamanan Data</h5>
<ul>
  <li> - Kami menggunakan langkah-langkah keamanan teknis dan organisasi untuk melindungi informasi pribadi Anda dari akses yang tidak sah, kehilangan, atau penyalahgunaan.</li>

<li><h5 class="my-1"> Berbagi Informasi dengan Pihak Ketiga</h5>
   <li>- Kami tidak akan membagikan informasi pribadi Anda kepada pihak ketiga tanpa izin Anda, kecuali jika diwajibkan oleh hukum.</li>
   <li>- Untuk tujuan operasional, kami dapat bekerja sama dengan penyedia layanan pihak ketiga yang terikat oleh perjanjian privasi.</li>
 </ul>
</li>  
<li><h5 class="my-1"> Hak Pengguna</h5>
<ul>
   <li>- Anda berhak mengakses, memperbaiki, atau menghapus informasi pribadi Anda dengan menghubungi tim dukungan kami.</li>
 </ul>
</li>  
<li><h5 class="my-1"> Perubahan Kebijakan Privasi</h5>
<ul>
   <li>- Kebijakan ini dapat diperbarui sewaktu-waktu. Perubahan akan diberitahukan melalui email atau aplikasi.</li>
   <li>- Kami mendorong Anda untuk meninjau Kebijakan Privasi ini secara berkala untuk memahami bagaimana kami melindungi informasi Anda.</li>
 </ul>
</li>  
</ol>
Jika Anda memiliki pertanyaan lebih lanjut mengenai Syarat Ketentuan atau Kebijakan Privasi ini, silakan hubungi kami melalui email di <a href="mailto:starfield.id@gmail.com">starfield.id@gmail.com</a> atau kunjungi situs resmi kami di <a href="http://www.starsender.id">www.starsender.id</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                form: {
                    email: "",
                    phone: "",
                },
                isLoading: false,
            };
        },
        mounted() {
        },
        methods: {
            async resetPassword() {
                this.isLoading = true;

                try {
                    let verify = await this.$store.dispatch("auth_reset_password", this.form);
                    this.$toast.success(verify.data.message);
                    await this.$router.push("/login");
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
                this.isLoading = false;
            },
        },
    };
</script>

<style scoped>
    .spinner {
        height: 1rem !important;
        width: 1rem !important;
    }

    .btn {
        line-height: 1rem !important;
    }
</style>